<template>
  <div class="committee-box">
    <div class="home-title">
      <div class="title" style="display: flex; align-items: center;color: #000;margin: 7px 0;">
        <img src="../../../public/img/home/solution/en_home_goldcircle.png" alt="">
        <div style="display: flex;flex-direction: column;margin-top: 20px;">
          <p style="color: #000;font-size: 1.5rem;margin-left: -15px;letter-spacing: 2px;">COMMITTEE COMPOSITION</p>
          <p style="color: #AAABAC;margin-top: 10px;">Committee Composition</p>
        </div>
      </div>
    </div>
    <div class="comm-table">
      <el-table :data="tableData" style="width: 100%;background-color: transparent;">
        <el-table-column prop="Director" label="Director" />
        <el-table-column prop="Audit" label="Audit Committee" />
        <el-table-column prop="Compensation" label="Compensation Committee" />
        <el-table-column prop="Nominating" label="Nominating & Corporate Governance Committ" />
      </el-table>
    </div>
  </div>
  <Footer />
</template>
        
  <script setup>
import { BoardofDirectorsExecutiveOfficers } from '@/data/index'
import Footer from "@/components/home/Footer.vue";

const tableData = [
  {
    Director: 'Heung Ming Wong',
    Audit: 'Chairpersion',
    Compensation: 'Member',
    Nominating: 'Member',
  },
  {
    Director: 'Ming He',
    Audit: 'Member',
    Compensation: 'Member',
    Nominating: 'Chairpersion',
  },
  {
    Director: 'Wei Zhu',
    Audit: 'Member',
    Compensation: 'Chairpersion',
    Nominating: 'Member',
  }
]


</script>
<style lang="scss" scoped>
.committee-box {
  width: 100%;
  height: auto;
  background: url("../../../public/img/Committee/21-composition.png") no-repeat;
  background-size: 100% 100%;
}
.home-title {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 7.5rem 0 3.667rem 0;
}
.comm-table {
  padding: 2.5rem 5rem;
  min-height: 400px;
}
::v-deep .el-table .cell {
  color: rgba(0, 0, 0, 1);
}
::v-deep .el-table tr {
  background-color: transparent;
}
::v-deep .el-table .el-table__cell {
  padding: 20px 0;
}
</style>
        